<template>
  <div class="dynamic">
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="../../../static/debris.png" />
    <div class="dynamic-content">
      <div class="content-top">
        <div class="bg-image1"></div>
        <div class="bg-image2"></div>
        <div class="top-title">
          <img
            class="home-icon"
            src="../../../static/scenery/home.png"
            alt=""
          />
          <span
            style="margin-right: 6px; font-weight: bold"
            @click="Jump('/', 0)"
            >首页</span
          >
          >
          <span style="margin-left: 6px" @click="Jump('/about')"
            >走进条子泥</span
          >
          >
          <span style="margin-left: 6px; color: #509ab1">条子泥动态</span>
        </div>
        <p class="image">景区新闻</p>
        <div class="tabs">
          <div :class="activeNum ? 'tabsB' : ''" @click="tabAcitve(1)">
            景区资讯
          </div>
          <div :class="activeNum ? '' : 'tabsB'" @click="tabAcitve(2)">
            媒体报道
          </div>
        </div>
        <div class="top-wrapper clearfix" style="transform: scale(0.8, 1)">
          <div class="left">
            <div class="carousel-box">
              <el-carousel
                ref="carousel"
                :autoplay="false"
                indicator-position="none"
                @change="carouselChange"
              >
                <el-carousel-item
                  v-for="(item, index) in carouselList"
                  :key="index"
                >
                  <img
                    :src="item.imgPath"
                    alt=""
                    class="img"
                    @click="goto(item)"
                  />
                </el-carousel-item>
              </el-carousel>
              <div class="carousel-date">
                <div>{{ detail.newsDate && detail.newsDate.slice(8) }}</div>
                <div>{{ detail.newsDate && detail.newsDate.slice(0, 7) }}</div>
              </div>
            </div>
            <p class="c-text-title">
              {{ detail.title }}
            </p>
            <p>
              {{ detail.content }}
            </p>
          </div>
          <div class="right">
            <ul>
              <li
                class="timeline-item selected clearfix"
                v-for="(item, index) in list"
                :key="index"
                @click="goto(item)"
              >
                <div class="date">
                  <div>{{ item.newsDate && item.newsDate.slice(8) }}</div>
                  <div>{{ item.newsDate && item.newsDate.slice(0, 7) }}</div>
                </div>
                <div class="content">
                  <p style="cursor: pointer">
                    {{ item.title }}
                  </p>
                  <p>
                    {{ item.content }}
                  </p>
                </div>
              </li>
            </ul>
            <!-- <el-pagination
              background
              next-text="下一页"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              layout="prev, pager, next"
              :page-size="3"
              :total="total"
            >
            </el-pagination> -->
            <router-link
              class="morebg"
              :to="{
                path: '/scenicNews',
                query: {
                  activeNum: this.activeNum,
                  newsType: this.info.newsType,
                },
              }"
              >查看更多</router-link
            >
          </div>
        </div>
        <div class="ckeckmore">
          <!-- <p class="more-text">查看更多</p>
          <span></span> -->
        </div>
      </div>
      <div class="content-bottom">
        <div class="bg-image3"></div>
        <div class="bg-image4"></div>
        <div class="bg-image5"></div>
        <p class="image">景区公告</p>
        <el-carousel
          :interval="4000"
          type="card"
          style="transform: scale(0.8, 1)"
        >
          <el-carousel-item v-for="(item, index) in noticeList" :key="index">
            <el-image
              @click="goto(item)"
              :src="item.imgPath"
              fit="fill"
            ></el-image>
            <!-- <img class="notice" :src="item.imgPath"/> -->
          </el-carousel-item>
        </el-carousel>
        <div class="ckeckmore">
          <!-- <p class="more-text">查看更多</p>
          <span></span> -->
        </div>
      </div>
    </div>
    <leftFloat :floatList="floatList" />
  </div>
</template>

<script>
import { slideshow } from "@/api/test/test";
import { tzn_tplbAPI, tzn_news, tzn_notice } from "@/api/test/scenery";
export default {
  name: "Scenery",
  components: {
    leftFloat: () => import("../../components/leftFloat.vue"),
  },
  data() {
    return {
      floatList: {
        list: [
          { name: "条子泥印象", url: "/about" },
          { name: "条子泥风光", url: "/scenery" },
          { name: "条子泥动态", url: "/dynamic" },
        ],
        title: "走进条子泥",
      },
      activeNum: false,
      slideshowList: [],
      activeName: "条子泥鸟类",
      imageTitleList: [],
      user: "",
      login: false,
      input: "",
      currentPage: 1,
      count: 0,
      ticket: "",
      serviceUrl: "",
      isShowDetial: false,
      selectIndex: 0,
      newsList: [],
      newsList2: [],
      carouselSelectedIndex: 0,
      itemDetial: {},
      // 分页
      currentPage: 1,
      info: {
        page: 0,
        size: 3,
        newsType: 1, //1景区资讯 2.媒体报道
      },
      carouselList: [],
      detail: {},
      list: [],
      total: null,
      noticeList: [],
    };
  },
  created() {},
  mounted() {
    // 头部轮播图
    slideshow().then((res) => {
      this.slideshowList = res;
    });
    // 图片目录
    tzn_tplbAPI().then((res) => {
      this.imageTitleList = res;
    });
    // 景区新闻
    this.tzn_news();
    this.tzn_notice();
  },
  methods: {
    tabAcitve(e) {
      switch (e) {
        case 1:
          this.activeNum = false;
          this.info.page = 0;
          this.info.newsType = 1;
          this.tzn_news();
          break;
        case 2:
          this.info.page = 0;
          this.activeNum = true;
          this.info.newsType = 2;
          this.tzn_news();
          break;

        default:
          break;
      }
    },
    //分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.info.page = val - 1;
      this.tzn_news(true);
    },
    // 左边列表跳换
    handleJump(url) {
      this.$router.push(url);
    },
    // 数据格式化
    decodeHTML(str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
    carouselChange(id) {
      this.carouselList.some((item, index) => {
        if (index == id) {
          this.detail = item;
        }
      });
    },
    async tzn_news(e) {
      const res = await tzn_news(this.info);
      const res2 = await tzn_news({
        page: 0,
        size: 20,
        newsType: this.info.newsType,
      });
      this.carouselList = [];
      res2.data.forEach((item) => {
        if (item.isCarousel == 1) {
          this.carouselList.push(item);
        }
      });
      if (e != true) {
        this.detail = res2.data[0];
      }
      console.log(res, "2231");
      this.list = res.data;
      this.total = res.count;
    },
    async tzn_notice() {
      const { data } = await tzn_notice();
      this.noticeList = data;
      console.log(data);
    },
    goto(item) {
      if (item.newsType === "1") {
        if (item.redirectUrl ?? "" !== "") {
          window.open(item.redirectUrl);
        } else {
          this.$router.push({
            name: "scenicDetails",
            path: "/scenicDetails",
            query: { item: JSON.stringify(item) },
          });
        }
      } else {
        window.open(item.redirectUrl);
      }
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  justify-content: center;
}
.tabs > div {
  width: 230px;
  height: 59px;
  line-height: 59px;
  text-align: center;
  margin: 15px;
  font-size: 23px;
  color: #509ab1;
  background: url("~static/tab.png") no-repeat;
}
.tabsB {
  background: url("~static/tabAcitve.png") no-repeat !important;
  background-size: cover !important;
  color: #f6d397 !important;
}
.dynamic {
  width: 100%;
}
>>> .el-carousel__item--card {
  /* width: auto; */
}
/deep/ .el-carousel__arrow {
  width: 106px;
  height: 106px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

/deep/ .el-carousel__arrow--left {
  background-image: url("../../../static/home/carousel-left.png");
}

/deep/ .el-carousel__arrow--right {
  background-image: url("../../../static/home/carousel-right.png");
}

/deep/.el-image {
  width: 100%;
}

/deep/.el-image :hover {
  cursor: pointer;
}

.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}

.dynamic-content {
  width: 1200px;
  margin: 0 auto;
}

.content-top {
  position: relative;
}

.top-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 92px;
  background-image: url("../../../static/scenery/title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
  letter-spacing: 5px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title > span:hover {
  cursor: pointer;
  color: #509ab1;
}
.image {
  width: 452px;
  height: 250px;
  background-image: url("../../../static/Hrbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 250px;
  margin: 55px auto;
  letter-spacing: 10px;
  font-size: 42px;
  color: #509ab1;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}

.top-wrapper .left {
  width: 580px;
  float: left;
  margin-top: 24px;
}

.carousel-box {
  position: relative;
}

.carousel-date {
  width: 85px;
  height: 85px;
  background: url("../../../static/dynamic/carouselDate.png") no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 13px;
  top: 17px;
  z-index: 99;
  margin-left: 0 !important;
}

.carousel-date div:nth-child(1) {
  font-size: 32px;
  color: #ffffff;
  text-align: center;
}

.carousel-date div:nth-child(2) {
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.index-box {
  position: absolute;
  right: 0;
  bottom: 5px;
  z-index: 99;
  display: flex;
  align-items: flex-end;
}

.index-box .index {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #509ab1;
  font-size: 18px;
  margin: 0 5px;
  user-select: none;
  cursor: pointer;
}

.index-box .select-index {
  background-color: #509ab1;
  color: #ffffff;
  width: 50px;
  height: 50px;
}

.c-text-title {
  font-size: 20px;
  font-weight: bold;
  color: #509ab1;
  line-height: 24px;
  margin-top: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 580px;
  cursor: pointer;
}

.top-wrapper .left p:last-child {
  font-size: 16px;
  font-weight: normal;
  color: #656565;
  line-height: 24px;
  margin-top: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.top-wrapper .right {
  float: right;
  width: 620px;
}

ul {
  list-style: none;
}

.timeline-item {
  margin-bottom: 29px;
}

.timeline-item .date {
  width: 114px;
  height: 105px;
  float: left;
  margin-left: 31px;
  background: url("../../../static/dynamic/timelineDate.png") no-repeat;
  background-size: cover;
  position: relative;
}

.timeline-item .date div:nth-child(1) {
  font-size: 26px;
  line-height: 20px;
  font-weight: bold;
  color: #dcab62;
  text-align: center;
  position: absolute;
  top: 35px;
  left: 30px;
}

.timeline-item .date div:nth-child(2) {
  font-size: 14px;
  font-weight: normal;
  color: #dcab62;
  line-height: 20px;
  white-space: nowrap;
  position: absolute;
  bottom: 26px;
  left: 16px;
  display: flex;
  justify-content: center;
}

.timeline-item .content {
  width: 464px;
  float: right;
}

.timeline-item.selected .content p:nth-child(1) {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 6;
  color: #509ab1;
  font-weight: bold;
  margin-top: 19px;
}

.timeline-item .content p:nth-child(1) {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 6;
  color: #333333;
  font-weight: bold;
  margin-top: 19px;
}

.timeline-item .content p:nth-child(2) {
  font-size: 16px;
  line-height: 24px;
  color: #656565;
  margin-top: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.el-pagination {
  display: flex;
  justify-content: center;
  margin: 18px 0 51px;
}
/deep/ .el-pagination button,
.el-pagination span {
  font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #509ab1;
}
/deep/ .el-pagination button:hover {
  color: #509ab1;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #509ab1;
}
.ckeckmore {
  /* width: 201px;
  height: 60px; */
  /* background-image: url('../../../static/scenery/more.png');
  background-repeat: no-repeat;
  background-size: 100% 100%; */
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 105px auto 119px;
}

.more-text {
  font-size: 22px;
  color: #509ab1;
  line-height: 60px;
}

.ckeckmore > span {
  width: 26px;
  height: 7px;
  background-image: url("../../../static/scenery/jiantou.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 10%;
}

.content-bottom {
  position: relative;
}

.notice {
  width: 620px;
}

.clearfix::before,
.clearfix::after {
  display: table;
  content: "";
  clear: both;
}

.bg-image1 {
  width: 390px;
  height: 292px;
  background-image: url("../../../static/scenery/dfbh.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -32%;
  top: 7%;
}

.bg-image2 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/hly.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -33%;
  bottom: 7%;
}

.bg-image3 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/hlz.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -33%;
  top: -18%;
}

.bg-image4 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/dfbh.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: -33%;
  bottom: 20%;
}

.bg-image5 {
  width: 428px;
  height: 353px;
  background-image: url("../../../static/scenery/hly.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -33%;
  bottom: 10%;
}
.img {
  width: 100%;
  height: 100%;
}
.carousel-box >>> .el-carousel__container {
  height: 350px;
}
.content-bottom >>> .el-carousel__container,
.content-bottom >>> .el-image {
  height: 470px;
}

.morebg {
  float: left;
  display: block;
  width: 201px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #509ab1;
  margin: 0 auto;
  background: url("~static/morebg.png") no-repeat;
  margin-left: 120px;
}
</style>
